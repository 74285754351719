import { Form, Input, Switch } from 'antd';
import { MODE_ADAPTIVE_WALL, MODE_DYNAMIC_WALL } from './WallModeSelector';
import { useEffect } from 'react';

const RenderOptionsFormItem = ({ env, form }) => {
  const renderToAAUsersFieldName = [env, 'am_settings', 'render_to_aa_users_disabled'];
  const wallModeFieldName = [env, 'am_settings', 'wall_mode'];

  const wallMode = Form.useWatch(wallModeFieldName, form);

  useEffect(() => {
    if (wallMode === MODE_ADAPTIVE_WALL) {
      form.setFieldValue(renderToAAUsersFieldName, false);
    }
  }, [wallMode, form]);

  return (
    <>
      <Form.Item
        label="Disable Render To AA Opted-In Users"
        name={renderToAAUsersFieldName}
        valuePropName="checked"
        tooltip="RL does not render for users who has Acceptable Ads enabled"
      >
        <Switch disabled={wallMode === MODE_ADAPTIVE_WALL} />
      </Form.Item>
      <Form.Item label="Page Views To Render" name={[env, 'am_settings', 'page_views_to_render']}>
        <Input type="number" min={0} disabled={wallMode === MODE_DYNAMIC_WALL} />
      </Form.Item>
      <Form.Item
        label="Render Interval In Days"
        name={[env, 'am_settings', 'render_interval_days']}
      >
        <Input type="number" min={0} />
      </Form.Item>
    </>
  );
};

export default RenderOptionsFormItem;
