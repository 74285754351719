import { round } from 'lodash';
import { Typography, Space } from 'antd';
import { CPM_DECIMALS_TO_DISPLAY } from './common';
import moment from 'moment';

const { Text } = Typography;

const RevShareListBaseItem = ({ isRLRevShare, revShare }) => {
  const {
    activated_at,
    programmatic_percent,
    ad_serving_cpm,
    direct_cpm,
    house_cpm,
    free_trial_enabled,
  } = revShare;

  let cpmSum = direct_cpm + house_cpm;
  if (!isRLRevShare) {
    cpmSum += ad_serving_cpm;
  }

  return (
    <>
      <div>
        <Text underline>{moment.utc(activated_at).format('MMM D, YYYY')}</Text>
      </div>

      <div>
        <Space>
          <Space style={{ minWidth: 160 }}>
            <Text disabled={!programmatic_percent}>Programmatic</Text>
            <Text strong disabled={!programmatic_percent}>
              {round(programmatic_percent * 100, 2)}%
            </Text>
          </Space>

          <Space>
            <Text disabled={!cpmSum}>CPM</Text>
            <Space>
              <Text strong disabled={!cpmSum}>
                ${cpmSum.toFixed(CPM_DECIMALS_TO_DISPLAY)}
              </Text>
              <Text italic disabled={!cpmSum}>
                (
              </Text>
              <Space size={6}>
                <Space size={6}>
                  <Text italic disabled={!direct_cpm}>
                    Direct
                  </Text>
                  <Text italic strong disabled={!direct_cpm}>
                    ${direct_cpm.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                  </Text>
                </Space>
                <Text italic disabled={!cpmSum}>
                  +
                </Text>
                <Space size={6}>
                  <Text italic disabled={!house_cpm}>
                    House
                  </Text>
                  <Text italic strong disabled={!house_cpm}>
                    ${house_cpm.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                  </Text>
                </Space>

                {!isRLRevShare && (
                  <>
                    <Text italic disabled={!cpmSum}>
                      +
                    </Text>
                    <Space size={6}>
                      <Text italic disabled={!ad_serving_cpm}>
                        Ad Serving
                      </Text>
                      <Text italic strong disabled={!ad_serving_cpm}>
                        ${ad_serving_cpm && ad_serving_cpm.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                      </Text>
                    </Space>
                  </>
                )}
              </Space>
              <Text italic disabled={!cpmSum}>
                )
              </Text>
            </Space>
          </Space>
        </Space>
      </div>
      <div>
        {free_trial_enabled && (
          <Text strong type="success">
            Free trial
          </Text>
        )}
      </div>
    </>
  );
};

export default RevShareListBaseItem;
