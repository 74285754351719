import { CloseCircleOutlined, PlayCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, message, Alert, DatePicker } from 'antd';
import React from 'react';
import { mutate } from 'swr';
import moment from 'moment';
import api from '../../../../services/api';
import { DeleteEntityPopconfirm, SaveEntityModal } from '../../../../services/handlers';
import { useAsync } from '../../../../services/hooks';

export const RevShareFreeTrialForm = ({ formInstance, saveError }) => (
  <Form
    name="rev-share-free-trial"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  >
    <Form.Item name="free_trial_end_at" label="Free trial end at" rules={[{ required: false }]}>
      <DatePicker
        disabledDate={(date) => date && date < moment().add(1, 'days').startOf('day')} // Disable all days before tomorrow
        showToday={false} // We hide the "Today" button because it will always be disabled
      />
    </Form.Item>

    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

export const EnableFreeTrial = ({ orgId }) => (
  <SaveEntityModal
    triggerRender={({ openModal }) => (
      <Button onClick={openModal} type="dashed" icon={<PlayCircleOutlined />} block>
        Enable Free Trial
      </Button>
    )}
    modalTitle="Enable Free Trial"
    transformBeforeSave={() => ({
      org_id: orgId,
    })}
    saveEntity={api.enableRLRevShareFreeTrial}
    onSuccess={() => {
      message.success('Free Trial successfully enabled!');
      mutate(['/RLRevShareList', orgId]);
    }}
    formComponent={RevShareFreeTrialForm}
    formInitialValues={() => {}}
    width={650}
  />
);

export const UpdateFreeTrial = ({ orgId }) => (
  <SaveEntityModal
    triggerRender={({ openModal }) => (
      <Button onClick={openModal} type="dashed" icon={<EditOutlined />} block>
        Change Free Trial
      </Button>
    )}
    modalTitle="Change Free Trial"
    transformBeforeSave={({ free_trial_end_at }) => ({
      org_id: orgId,
      free_trial_end_at: free_trial_end_at.startOf('day').toISOString(),
    })}
    saveEntity={api.updateRLRevShareFreeTrial}
    onSuccess={() => {
      message.success('Free Trial successfully updated!');
      mutate(['/RLRevShareList', orgId]);
    }}
    formComponent={RevShareFreeTrialForm}
    width={650}
  />
);

export const DisableFreeTrial = ({ orgId }) => {
  const { execute: disableFreeTrial } = useAsync(() =>
    api.disableRLRevShareFreeTrial({ org_id: orgId })
  );

  return (
    <DeleteEntityPopconfirm
      triggerRender={({ openModal }) => (
        <Button onClick={openModal} type="dashed" icon={<CloseCircleOutlined />} block danger>
          Disable Free Trial
        </Button>
      )}
      prompt="Are you sure you want to disable Free Trial?"
      deleteEntity={disableFreeTrial}
      onDeleted={() => {
        message.success('Free Trial was successfully disabled!');
        mutate(['/RLRevShareList', orgId]); // Trigger rev share refresh to reflect deletion
      }}
    />
  );
};
