import { useEffect } from 'react';
import { Form, Select, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { MODE_FOOTER } from './ModeFormItem';
import DynamicWallSettings from './DynamicWallSettings';

export const MODE_SOFT_WALL = 'soft';
export const MODE_HARD_WALL = 'hard';
export const MODE_ADAPTIVE_WALL = 'adaptive';
export const MODE_DYNAMIC_WALL = 'dynamic';

const defaultMode = MODE_SOFT_WALL;

const WALL_MODE_OPTIONS = [
  {
    value: MODE_SOFT_WALL,
    label: 'Soft Wall - All users can dismiss the wall (Recommended)',
  },
  {
    value: MODE_HARD_WALL,
    label: 'Hard Wall - All users must allow ads to continue',
  },
  {
    value: MODE_ADAPTIVE_WALL,
    label:
      'Adaptive Wall - Renders hard wall to AA opted out users only, and soft wall for AA opted in users',
  },
  {
    value: MODE_DYNAMIC_WALL,
    label: 'Dynamic Wall - Renders hard wall after dismissing soft wall X times',
  },
];

const WallModeSelector = ({ env, form, disabled }) => {
  const viewMode = Form.useWatch([env, 'am_settings', 'view'], form);
  const wallModeFieldName = [env, 'am_settings', 'wall_mode'];
  const wallModeValue = Form.useWatch(wallModeFieldName, form);

  const isFooterView = viewMode === MODE_FOOTER;

  const isDynamicWallMode = wallModeValue === MODE_DYNAMIC_WALL;
  const dynamicWallSettings = Form.useWatch([env, 'am_settings', 'dynamic_wall_settings']);

  // Filter options based on view mode
  const availableOptions = WALL_MODE_OPTIONS.filter((option) => {
    if (isFooterView) {
      return option.value === MODE_SOFT_WALL;
    }
    return true;
  });

  useEffect(() => {
    if (isDynamicWallMode && !dynamicWallSettings) {
      form.setFieldValue([env, 'am_settings', 'dynamic_wall_settings', 'pageviews_count'], 2);
      form.setFieldValue([env, 'am_settings', 'dynamic_wall_settings', 'dismiss_count'], 2);
    }
  }, [isDynamicWallMode, form, env, dynamicWallSettings]);

  return (
    <>
      <Form.Item
        label={
          <Space>
            Wall Mode
            <Tooltip
              title={
                <>
                  Choose how your message wall interacts with different user groups
                  <br />
                  <small>AA = Acceptable Ads</small>
                </>
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        }
        name={wallModeFieldName}
      >
        <Select
          defaultValue={defaultMode}
          disabled={disabled}
          options={availableOptions}
          value={wallModeValue}
          style={{ width: '100%' }}
        />
      </Form.Item>
      {isDynamicWallMode && <DynamicWallSettings env={env} />}
    </>
  );
};

export default WallModeSelector;
