import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, message, Alert, DatePicker } from 'antd';
import React from 'react';
import { mutate } from 'swr';
import api from '../../../services/api';
import moment from 'moment';
import { DeleteEntityPopconfirm, SaveEntityModal } from '../../../services/handlers';
import { useAsync } from '../../../services/hooks';
import {
  percentageParser,
  percentageFormatter,
  dollarParser,
  dollarFormatter,
  transformFromFormValues,
  transformToFormValues,
} from './common';

export const RevShareFormMain = ({ formInstance, saveError }) => (
  <Form
    name="rev-share"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  >
    <Form.Item
      name="activated_at"
      label="Starting Date"
      rules={[{ required: true, message: 'Please input a starting date!' }]}
    >
      <DatePicker
        disabledDate={(date) => date && date < moment().add(1, 'days').startOf('day')} // Disable all days before tomorrow
        showToday={false} // We hide the "Today" button because it will always be disabled
      />
    </Form.Item>

    <Form.Item
      name="programmatic_percent"
      label="Programmatic"
      tooltip="The % of a publisher's programmatic revenue BT gets"
      rules={[{ required: true, type: 'number', message: 'Please input a valid percentage!' }]}
    >
      <InputNumber min={0} max={100} formatter={percentageFormatter} parser={percentageParser} />
    </Form.Item>

    {/* CPM fields */}
    <Form.Item name="direct_cpm" label="Direct CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>
    <Form.Item name="house_cpm" label="House CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>
    <Form.Item name="ad_serving_cpm" label="Ad Serving CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>

    {/* BT Demand fields */}
    <Form.Item
      name="bt_demand_percent"
      label="BT Demand"
      tooltip="The % of a publisher's BT Demand revenue BT gets"
      rules={[{ required: true, type: 'number', message: 'Please input a valid percentage!' }]}
    >
      <InputNumber min={0} max={100} formatter={percentageFormatter} parser={percentageParser} />
    </Form.Item>
    <Form.Item name="bt_demand_ad_serving_cpm" label="BT Demand Ad Serving CPM">
      <InputNumber min={0} step={0.01} formatter={dollarFormatter} parser={dollarParser} />
    </Form.Item>

    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

export const AddRevShare = ({ orgId }) => (
  <SaveEntityModal
    triggerRender={({ openModal }) => (
      <Button onClick={openModal} type="dashed" icon={<PlusOutlined />} block>
        Set Upcoming Rev Share
      </Button>
    )}
    modalTitle="Set Upcoming Rev Share"
    // Add org id, and transform form values before api request
    transformBeforeSave={(values) => ({
      org_id: orgId,
      ...transformFromFormValues(values),
    })}
    saveEntity={api.createRevShare}
    onSuccess={() => {
      // Show success message, trigger rev share refresh to reflect new addition, and hide form
      message.success(`Rev share settings successfully created!`);
      mutate(['/RevShareList', orgId]);
    }}
    formComponent={RevShareFormMain}
    formInitialValues={transformToFormValues()}
    width={650}
  />
);

export const EditRevShare = ({ revShare }) => (
  <SaveEntityModal
    triggerRender={({ openModal }) => (
      <Button type="link" size="small" onClick={openModal}>
        <EditOutlined />
      </Button>
    )}
    modalTitle="Edit Rev Share"
    // Add id, and transform form values before api request
    transformBeforeSave={(values) => ({
      id: revShare.id,
      ...transformFromFormValues(values),
    })}
    saveEntity={api.updateRevShare}
    onSuccess={({ rev_share: revShare }) => {
      // Show success message, trigger rev share refresh to reflect update, and hide form
      message.success(`Rev share settings successfully updated!`);
      mutate(['/RevShareList', revShare.org_id]);
    }}
    formComponent={RevShareFormMain}
    formInitialValues={transformToFormValues(revShare)}
    width={650}
  />
);

export const DeleteRevShare = ({ revShare, isDisabled }) => {
  const { execute: deleteRevShare } = useAsync(() => api.deleteRevShare(revShare.id));

  return (
    <DeleteEntityPopconfirm
      isDisabled={isDisabled}
      deleteEntity={deleteRevShare}
      onDeleted={() => {
        message.success('Future rev share settings successfully deleted!');
        mutate(['/RevShareList', revShare.org_id]); // Trigger rev share refresh to reflect deletion
      }}
    />
  );
};
