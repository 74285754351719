import { Button, Space, Timeline } from 'antd';
import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import api from '../../../services/api';
import { NoSaveModal } from '../../../services/handlers';
import { findCurrentAndNextRevShares, isRevShareZero, formatRevShare } from './common';
import { AddRevShare } from './RevSharesForms';
import RevShareListItem from './RevShareListItem';

const RevSharesList = ({ orgId, isViewOnly }) => {
  const { data: revShares, error: revSharesError } = useSWR(['/RevShareList', orgId], () =>
    api.listRevShares({ org_id: orgId })
  );
  const { currentRevShare, nextRevShare } = findCurrentAndNextRevShares(revShares);

  return revSharesError ? (
    `Error retrieving org's revenue share`
  ) : revShares && currentRevShare ? (
    <NoSaveModal
      width={820}
      triggerRender={({ openModal }) => {
        const currRevShareIsEmpty = isRevShareZero(currentRevShare);
        const currentRevShareString = formatRevShare(currentRevShare);
        const nextRevShareString = nextRevShare
          ? ` (until ${moment.utc(nextRevShare.activated_at).format('MMM D, YYYY')})`
          : '';

        return (
          <Button
            type="link"
            size="small"
            danger={currRevShareIsEmpty}
            style={{ fontWeight: currRevShareIsEmpty ? 'bold' : 'normal' }}
            onClick={openModal}
          >
            {`${currentRevShareString}${nextRevShareString}`}
          </Button>
        );
      }}
      modalTitle="Blockthrough Revenue Share"
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {isViewOnly ? null : <AddRevShare orgId={orgId} />}
        <Timeline mode="left">
          {revShares.map((revShare) => {
            const isCurrentRevShare = currentRevShare.activated_at === revShare.activated_at;
            const isAnUpcomingRevShare = moment
              .utc(revShare.activated_at)
              .isAfter(currentRevShare.activated_at);

            return (
              <RevShareListItem
                key={revShare.id}
                revShare={revShare}
                isCurrentRevShare={isCurrentRevShare}
                isAnUpcomingRevShare={isAnUpcomingRevShare}
                isViewOnly={isViewOnly}
              />
            );
          })}
        </Timeline>
      </Space>
    </NoSaveModal>
  ) : null;
};

export default RevSharesList;
