import { round } from 'lodash';
import moment from 'moment';

export const CPM_DECIMALS_TO_DISPLAY = 4;

export const percentageFormatter = (value) => `${value}%`;
export const percentageParser = (value) => value.replace('%', '');
export const dollarFormatter = (value) => `$ ${value}`;
export const dollarParser = (value) => value.replace(/\$\s?/g, '');

export const findCurrentAndNextRevShares = (revShares = []) => {
  let currentRevShare;
  let nextRevShare;

  revShares.forEach((revShare) => {
    const activationMoment = moment.utc(revShare.activated_at);
    if (activationMoment.isBefore()) {
      // Find most recent rev share that was activated before today - set it as currentRevShare
      if (!currentRevShare || activationMoment.isAfter(currentRevShare.activated_at)) {
        currentRevShare = revShare;
      }
    } else if (activationMoment.isSameOrAfter()) {
      // Find next upcoming rev share that will be activated starting or after today - set it as nextRevShare
      if (!nextRevShare || activationMoment.isBefore(nextRevShare.activated_at)) {
        nextRevShare = revShare;
      }
    }
  });

  return { currentRevShare, nextRevShare };
};

export const isRevShareZero = (revShare) => {
  if (revShare) {
    const cpmSum = revShare.ad_serving_cpm + revShare.direct_cpm + revShare.house_cpm;
    return revShare.programmatic_percent === 0 && cpmSum === 0 && revShare.bt_demand_percent === 0;
  }
};

export const formatRevShare = (revShare) => {
  if (revShare) {
    const cpmSum = revShare.ad_serving_cpm + revShare.direct_cpm + revShare.house_cpm;
    const cpmString = cpmSum ? ` + $${cpmSum.toFixed(CPM_DECIMALS_TO_DISPLAY)} CPM` : '';

    const hasBTDemand = revShare.bt_demand_percent || revShare.bt_demand_ad_serving_cpm;
    const BTDemandString = hasBTDemand ? ' + BT Demand' : '';

    return `${round(revShare.programmatic_percent * 100, 2)}%${cpmString}${BTDemandString}`;
  } else {
    return '';
  }
};

/**
 * Transforms the data from `revShare` into values that the form component expects/can handle.
 * Can also be used to set initial form values if no `revShare` is passed in.
 * @param {Object} revShare data returned by the API for an existing rev share being edited in the rev share form.
 */
export const transformToFormValues = ({
  activated_at,
  programmatic_percent = 0,
  direct_cpm = 0,
  house_cpm = 0,
  ad_serving_cpm = 0,
  bt_demand_ad_serving_cpm = 0,
  bt_demand_percent = 0,

  rl_programmatic_percent = 0,
  rl_direct_cpm = 0,
  rl_house_cpm = 0,
} = {}) => {
  return {
    // Transform ISO datetime string into moment date (time returned from API should always be T00:00:00Z)
    activated_at: activated_at ? moment.utc(activated_at) : null,

    // Transform these percent values (which are set as a decimal representation (i.e. a number between 0 and 1) in the API) into percentage numbers between 0 and 100
    programmatic_percent: round(programmatic_percent * 100, 2),
    bt_demand_percent: round(bt_demand_percent * 100, 2),

    direct_cpm,
    house_cpm,
    ad_serving_cpm,
    bt_demand_ad_serving_cpm,

    rl_programmatic_percent: round(rl_programmatic_percent * 100, 2),
    rl_direct_cpm,
    rl_house_cpm,
  };
};

/**
 * Transforms the data from the form component into `revShare` values that the create/edit API call expects/can handle.
 * @param {Object} formValues data returned by the rev share form for a new or existing (being edited) rev share about to be saved through the API.
 */
export const transformFromFormValues = ({
  activated_at,
  programmatic_percent,
  direct_cpm,
  house_cpm,
  ad_serving_cpm,
  bt_demand_ad_serving_cpm,
  bt_demand_percent,
  rl_programmatic_percent,
  rl_direct_cpm,
  rl_house_cpm,
}) => {
  return {
    // Transform moment date into ISO datetime string (and set its time to T00:00:00Z)
    activated_at: activated_at.startOf('day').toISOString(),

    // Transform these percent values (which are set as a percentage number between 0 and 100 in the form fields) into their decimal representation (i.e. a number between 0 and 1)
    programmatic_percent: round(programmatic_percent / 100, 4),
    bt_demand_percent: round(bt_demand_percent / 100, 4),

    direct_cpm,
    house_cpm,
    ad_serving_cpm,
    bt_demand_ad_serving_cpm,

    rl_programmatic_percent: round(rl_programmatic_percent / 100, 4),
    rl_direct_cpm,
    rl_house_cpm,
  };
};

export const transformToRLFormValues = ({
  activated_at,
  programmatic_percent = 0,
  direct_cpm = 0,
  house_cpm = 0,
} = {}) => {
  return {
    // Transform ISO datetime string into moment date (time returned from API should always be T00:00:00Z)
    activated_at: activated_at ? moment.utc(activated_at) : null,

    // Transform these percent values (which are set as a decimal representation (i.e. a number between 0 and 1) in the API) into percentage numbers between 0 and 100
    programmatic_percent: round(programmatic_percent * 100, 2),

    direct_cpm,
    house_cpm,
  };
};

export const transformFromRLFormToValues = ({
  activated_at,
  programmatic_percent,
  direct_cpm,
  house_cpm,
}) => {
  return {
    activated_at: activated_at.startOf('day').toISOString(),
    programmatic_percent: round(programmatic_percent / 100, 4),
    direct_cpm,
    house_cpm,
  };
};

