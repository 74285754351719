import { Space, Timeline, Row, Col } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import api from '../../../../services/api';
import { NoSaveModal } from '../../../../services/handlers';
import { findCurrentAndNextRevShares } from '../common';
import { AddRevShare } from './RevShareForms';
import RevShareListItem from '../RevShareListItem';
import RevShareOpenBtn from './RevShareOpenBtn';
import { EnableFreeTrial, DisableFreeTrial, UpdateFreeTrial } from './RevShareFreeTrialForm';

const RLRevSharesList = ({ orgId, isViewOnly }) => {
  const { data: revShares, error: revSharesError } = useSWR(['/RLRevShareList', orgId], () =>
    api.listRLRevShares({ org_id: orgId })
  );

  const [freeTrialState, setFreeTrialState] = useState(false);
  const { currentRevShare, nextRevShare } = findCurrentAndNextRevShares(revShares);

  useEffect(() => {
    if (!revShares) {
      return;
    }

    const freeTrialState = revShares.some((revShare) => revShare?.free_trial_enabled);
    setFreeTrialState(freeTrialState);
  }, [revShares]);

  return revSharesError ? (
    `Error retrieving org's revenue share`
  ) : revShares && revShares.length >= 0 ? (
    <NoSaveModal
      width={820}
      triggerRender={({ openModal }) => (
        <RevShareOpenBtn
          openModal={openModal}
          currentRevShare={currentRevShare}
          nextRevShare={nextRevShare}
        />
      )}
      modalTitle="Readership Link Revenue Share"
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {!isViewOnly && (
          <Row>
            <Col span={12}>
              <AddRevShare orgId={orgId} />
            </Col>
            <Col span={12}>
              {freeTrialState ? (
                <Row>
                  <Col span={12}>
                    <UpdateFreeTrial orgId={orgId} />
                  </Col>
                  <Col span={12}>
                    <DisableFreeTrial orgId={orgId} />
                  </Col>
                </Row>
              ) : (
                <EnableFreeTrial orgId={orgId} />
              )}
            </Col>
          </Row>
        )}
        <Timeline mode="left">
          {revShares.map((revShare) => {
            let isCurrentRevShare = false;
            let isAnUpcomingRevShare = false;

            if (currentRevShare) {
              isCurrentRevShare = currentRevShare.activated_at === revShare.activated_at;
              isAnUpcomingRevShare = moment
                .utc(revShare.activated_at)
                .isAfter(currentRevShare.activated_at);
            }

            return (
              <RevShareListItem
                key={revShare.id}
                revShare={revShare}
                isCurrentRevShare={isCurrentRevShare}
                isAnUpcomingRevShare={isAnUpcomingRevShare}
                isViewOnly={isViewOnly}
                isRLRevShare={true}
              />
            );
          })}
        </Timeline>
      </Space>
    </NoSaveModal>
  ) : null;
};

export default RLRevSharesList;
