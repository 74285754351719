import React from 'react';
import { Form, Input } from 'antd';

const DynamicWallSettings = ({ env }) => (
  <>
    <Form.Item
      tooltip="Number of pageviews until wall renders again after first render"
      required={true}
      label="PageViews Count"
      name={[env, 'am_settings', 'dynamic_wall_settings', 'pageviews_count']}
      rules={[{ required: true, message: 'PageViews Count is required.' }]}
    >
      <Input type="number" min={1} defaultValue={2} />
    </Form.Item>
    <Form.Item
      tooltip="Number of times softwall can be dismissed until hard wall renders"
      required={true}
      label="Dismiss Count"
      name={[env, 'am_settings', 'dynamic_wall_settings', 'dismiss_count']}
      rules={[{ required: true, message: 'Dismiss Count is required.' }]}
    >
      <Input type="number" min={1} defaultValue={2} />
    </Form.Item>
  </>
);

export default DynamicWallSettings;
