import { languages } from './languages';
import { Form, Input, message, Select } from 'antd';
import TitleFormItem from '../TitleFormItem';
import { MODE_CUSTOM_CTA } from '../ModeFormItem';
import { useEffect, useMemo } from 'react';
import useSWR from 'swr';
import api from '../../../../services/api';
import { generateErrorMessage } from '../../../../services/utils';

const LandingTextMaxChar = 500;
const LandingTextMinChar = 100;

const LanguageInput = ({
  env,
  language,
  onChange,
  existKeys,
  viewMode,
  hardWallEnabled,
  adaptiveHardWallEnabled,
  formInstance,
}) => {
  const options = languages
    .filter((v) => !existKeys?.includes(v.code) || language === v.code)
    .map((v) => ({ label: v.name, value: v.code }));

  useEffect(() => {
    return () => {
      formInstance.setFieldValue([env, 'am_settings', 'i18n', language], undefined);
    };
  }, []);

  const { data: defaultTranslationResponse, error: defaultTranslationError } = useSWR(
    ['/RLDefaultTranslations', language],
    () => api.rlDefaultTranslations(language)
  );

  useMemo(() => {
    if (defaultTranslationError) {
      message.error(generateErrorMessage(defaultTranslationError));
    }
  }, [defaultTranslationError]);

  return (
    <>
      <div className="ant-form-item">
        <div className="ant-row">
          <div className="ant-col ant-form-item-label">
            <label>Languages</label>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content">
                <Select
                  showSearch
                  className="ant-select-in-form-item"
                  placeholder="Select a language"
                  optionFilterProp="children"
                  defaultValue={language}
                  onChange={onChange}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Form.Item label="Name" name={[env, 'am_settings', 'i18n', language, 'name']}>
        <Input />
      </Form.Item>
      <TitleFormItem
        env={env}
        language={language}
        placeholder={defaultTranslationResponse?.title}
      />

      {!hardWallEnabled && (
        <>
          <Form.Item
            label={`Landing Body Text`}
            name={[env, 'am_settings', 'i18n', language, 'landing_text']}
          >
            <Input.TextArea
              minLength={LandingTextMinChar}
              maxLength={LandingTextMaxChar}
              autoSize
              placeholder={defaultTranslationResponse?.landing_text}
            />
          </Form.Item>
          <Form.Item
            label={`Landing Body Text SCA`}
            name={[env, 'am_settings', 'i18n', language, 'landing_text_sca']}
          >
            <Input.TextArea
              minLength={LandingTextMinChar}
              maxLength={LandingTextMaxChar}
              autoSize
              placeholder={defaultTranslationResponse?.landing_text}
            />
          </Form.Item>
        </>
      )}

      {(hardWallEnabled || adaptiveHardWallEnabled) && (
        <>
          <Form.Item
            label={`Hard Wall Landing Body Text`}
            name={[env, 'am_settings', 'i18n', language, 'landing_text_hard_wall']}
          >
            <Input.TextArea
              minLength={LandingTextMinChar}
              maxLength={LandingTextMaxChar}
              autoSize
              placeholder={defaultTranslationResponse?.landing_text}
            />
          </Form.Item>
          <Form.Item
            label={`Hard Wall Landing Body Text SCA`}
            name={[env, 'am_settings', 'i18n', language, 'landing_text_hard_wall_sca']}
          >
            <Input.TextArea
              minLength={LandingTextMinChar}
              maxLength={LandingTextMaxChar}
              autoSize
              placeholder={defaultTranslationResponse?.landing_text}
            />
          </Form.Item>
        </>
      )}

      <Form.Item
        label={`Call to action`}
        name={[env, 'am_settings', 'i18n', language, 'call_to_action']}
      >
        <Input placeholder={defaultTranslationResponse?.allow} />
      </Form.Item>

      <Form.Item label={`Continue`} name={[env, 'am_settings', 'i18n', language, 'continue']}>
        <Input placeholder={defaultTranslationResponse?.continue} />
      </Form.Item>

      {viewMode === MODE_CUSTOM_CTA && (
        <>
          <p>
            <b>Custom CTA modal</b>
          </p>
          <Form.Item
            label={`Primary text`}
            required
            name={[env, 'am_settings', 'i18n', language, 'custom_cta', 'primary_text']}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={`Secondary text`}
            required
            name={[env, 'am_settings', 'i18n', language, 'custom_cta', 'secondary_text']}
          >
            <Input />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default LanguageInput;
