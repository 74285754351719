import { ClockCircleOutlined } from '@ant-design/icons';
import { round } from 'lodash';
import { Typography, Divider, Space, Timeline } from 'antd';
import { CPM_DECIMALS_TO_DISPLAY } from './common';
import RevShareListBaseItem from './RevShareListBaseItem';
import { EditRevShare, DeleteRevShare } from './RevSharesForms';
import {
  EditRevShare as RLEditRevShare,
  DeleteRevShare as RLDeleteRevShare,
} from './RLRevShares/RevShareForms';

const { Text } = Typography;

const RevShareListItem = ({
  revShare,
  isCurrentRevShare,
  isAnUpcomingRevShare,
  isViewOnly,
  isRLRevShare = false,
}) => {
  const { id, bt_demand_ad_serving_cpm, bt_demand_percent } = revShare;

  const actionBtns = [];
  if (isRLRevShare) {
    actionBtns.push(<RLEditRevShare key="edit-rev-share" revShare={revShare} />);
    actionBtns.push(<RLDeleteRevShare key="delete-rev-share" revShare={revShare} />);
  } else {
    actionBtns.push(<EditRevShare key="edit-rev-share" revShare={revShare} />);
    actionBtns.push(<DeleteRevShare key="delete-rev-share" revShare={revShare} />);
  }

  return (
    <Timeline.Item
      key={id}
      color={isCurrentRevShare ? 'blue' : 'gray'}
      dot={
        isAnUpcomingRevShare ? (
          <ClockCircleOutlined style={{ fontSize: 17, position: 'relative', top: -1 }} />
        ) : undefined
      }
    >
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <div>
          <RevShareListBaseItem revShare={revShare} isRLRevShare={isRLRevShare} />

          {!isRLRevShare && (
            <div>
              <Space>
                <Space style={{ minWidth: 160 }}>
                  <Text disabled={!bt_demand_percent}>BT Demand</Text>
                  <Text strong disabled={!bt_demand_percent}>
                    {round(bt_demand_percent * 100, 2)}%
                  </Text>
                </Space>
                <Space>
                  <Text disabled={!bt_demand_ad_serving_cpm}>BT Demand Ad Serving CPM</Text>
                  <Text strong disabled={!bt_demand_ad_serving_cpm}>
                    ${bt_demand_ad_serving_cpm.toFixed(CPM_DECIMALS_TO_DISPLAY)}
                  </Text>
                </Space>
              </Space>
            </div>
          )}
        </div>
        <Space size={0} split={<Divider type="vertical" />} style={{ minWidth: 45 }}>
          {!isViewOnly && isAnUpcomingRevShare ? actionBtns : ''}
        </Space>
      </Space>
    </Timeline.Item>
  );
};

export default RevShareListItem;
