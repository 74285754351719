import { Button } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { isRevShareZero, formatRevShare } from '../common';

const RevShareOpenBtn = ({ openModal, currentRevShare, nextRevShare }) => {
  const currRevShareIsEmpty = isRevShareZero(currentRevShare);

  const revShareBtnText = useMemo(() => {
    let btnText = 'Empty';

    if (currentRevShare) {
      const currentRevShareString = formatRevShare(currentRevShare);
      const nextRevShareString = nextRevShare
        ? ` (until ${moment.utc(nextRevShare.activated_at).format('MMM D, YYYY')})`
        : '';
      btnText = `${currentRevShareString}${nextRevShareString}`;
    }

    return btnText;
  }, [currentRevShare, nextRevShare]);

  return (
    <Button
      type="link"
      size="small"
      danger={currRevShareIsEmpty}
      style={{ fontWeight: currRevShareIsEmpty ? 'bold' : 'normal' }}
      onClick={openModal}
    >
      {revShareBtnText}
    </Button>
  );
};

export default RevShareOpenBtn;
